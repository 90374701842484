

import { useRouter } from "vue-router";
import {computed, ref} from "vue";
import { IonContent, IonIcon, IonPage } from '@ionic/vue';
import { SelectOption } from "@/models/SelectOptionModel";
import { caretDownOutline } from "ionicons/icons";
import { AppInstance } from "@/models/AppInstanceModel";
import { openSelectModal } from "@/services/SelectModalService";
import { execute } from "@/mixins/LoadingMixin";
import {getCompleteAppInstances} from "@/services/AppInstanceService";
import {defineComponent} from "@vue/runtime-core";
import {useStore} from "vuex";

export default defineComponent({
    name: 'Home',
    components: {
        IonContent,
        IonPage,
        IonIcon
    },
    async ionViewWillEnter() {
        const currentUser = this.store.getters['auth/loggedInUser'];
        this.canCreateJourneySet = currentUser.permissions?.includes("CreateJourneySet");
        this.canCreateTemplate = currentUser.permissions?.includes("CreateJourneyTemplate");
        this.canSelectDisabled = currentUser.permissions?.includes("ManageAppInstances");
        this.canCreate = this.canCreateJourneySet || this.canCreateTemplate;
        await this.store.dispatch('app/setPageTitle', '');
        await execute( async () => {
            this.appInstances = await getCompleteAppInstances();
            const selectedApp = this.appInstances.find((instance: AppInstance) => instance.id === this.store.getters['app/selectedAppInstanceId']);
            this.preferredInstance = selectedApp?.name;
            this.isSuspended = selectedApp?.suspended ?? false;
            this.instanceSelectOptions = this.appInstances.map((instance: AppInstance) => {
                return {
                    value: instance.id,
                    text: instance.name + (instance.suspended ? ` - ${this.language.suspendedContact}` : ''),
                    isChecked: instance.id === this.store.getters['app/selectedAppInstanceId'],
                    disabled: instance.suspended
                } as SelectOption
            });
        }, this.language.errors.gettingAppInstances);
    },
    setup() {
        const store = useStore();
        const router = useRouter();
        const appInstances = ref([] as AppInstance[]);
        const preferredInstance = ref();
        const instanceSelectOptions = ref([] as SelectOption[]);
        const canCreateJourneySet = ref(false);
        const canCreateTemplate = ref(false);
        const canSelectDisabled = ref(false);
        const canCreate = ref(false);
        const language = computed(() => store.getters['app/language']);
        const isSuspended = ref(false);

        function goToNewJourneySet() {
            router.push({ name: 'journeySetInstructions' });
        }

        function goToNewTemplate() {
            router.push({ name: 'templateInstructions' });
        }

        function goToViewTemplates() {
            router.push({ name: 'templates' });
        }

        function goToViewJourneySets() {
            router.push({ name: 'journeySetGroups' });
        }

        async function selectPreference() {
            const partnersSelect = await openSelectModal(language.value.languages, 'radio', instanceSelectOptions.value,canSelectDisabled.value);
            partnersSelect.onWillDismiss().then(async ({ data }: any) => {
                preferredInstance.value = data.find((option: SelectOption) => option.isChecked)?.text;
                isSuspended.value = data.find((option: SelectOption) => option.isChecked)?.disabled;
                if(isSuspended.value) {
                  preferredInstance.value = preferredInstance.value.replace(` - ${language.value.suspendedContact}`,'');
                }
                
                const appInstanceId = data.find((option: SelectOption) => option.isChecked)?.value;
                await store.dispatch('app/setSelectedAppInstanceId', appInstanceId);

              instanceSelectOptions.value = appInstances.value.map((instance: AppInstance) => {
                return {
                  value: instance.id,
                  text: instance.name + (instance.suspended ? ` - ${language.value.suspendedContact}` : ''),
                  isChecked: instance.id === appInstanceId,
                  disabled: instance.suspended
                } as SelectOption
              });
            });          
        }
        
        return {
            goToNewJourneySet,
            goToNewTemplate,
            goToViewTemplates,
            goToViewJourneySets,
            preferredInstance,
            instanceSelectOptions,
            caretDownOutline,
            openSelectModal,
            appInstances,
            canCreateJourneySet,
            canSelectDisabled,
            canCreateTemplate,
            canCreate,
            isSuspended,
            selectPreference,
            language,
            store,
            notHome: computed(() => router.currentRoute.value.name !== 'home')
        }
    }
});
