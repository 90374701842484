<template>
    <ion-page>
        <ion-content :fullscreen="true">
            <div v-if="!notHome"><ion-icon src="assets/img/CC-Logo-2-2.svg" class="pattern-logo"></ion-icon></div>
            <h2>
                <span>{{ language.welcomeTo }}</span> <br />
                <span>{{ language.pattern }} {{ language.contentCreator }}</span>
            </h2>
            <div class="input-group">
                <p>{{ language.chooseLanguage }}</p>
                <button class="custom-button button-dark" @click="selectPreference">
                    <span>{{ preferredInstance }}</span>
                    <ion-icon :icon="caretDownOutline" />
                </button>
                <p style="margin-top: 20px;" v-if="isSuspended">{{ language.suspendedContact }}</p>
            </div>
            <hr>
<!--             <div class="input-group" v-if="canCreate">
                <p>{{ language.createNew }}</p>
                <button class="custom-button button-primary" @click="goToNewJourneySet" v-if="canCreateJourneySet" :disabled="isSuspended && !canSelectDisabled">{{ language.journeySet }}</button>
                <button class="custom-button button-primary" @click="goToNewTemplate" v-if="canCreateTemplate" :disabled="isSuspended && !canSelectDisabled">{{ language.journeyTemplate }}</button>
            </div>
            <hr v-if="canCreate"> -->
            <div class="input-group">
                <p>{{ language.viewExisting }}</p>
                <button class="custom-button button-primary" @click="goToViewJourneySets" :disabled="isSuspended && !canSelectDisabled">{{ language.journeySets }}</button>
                <button class="custom-button button-primary" @click="goToViewTemplates" :disabled="isSuspended && !canSelectDisabled">{{ language.journeyTemplates }}</button>
            </div>
        </ion-content>
    </ion-page>
</template>

<script lang="ts">

import { useRouter } from "vue-router";
import {computed, ref} from "vue";
import { IonContent, IonIcon, IonPage } from '@ionic/vue';
import { SelectOption } from "@/models/SelectOptionModel";
import { caretDownOutline } from "ionicons/icons";
import { AppInstance } from "@/models/AppInstanceModel";
import { openSelectModal } from "@/services/SelectModalService";
import { execute } from "@/mixins/LoadingMixin";
import {getCompleteAppInstances} from "@/services/AppInstanceService";
import {defineComponent} from "@vue/runtime-core";
import {useStore} from "vuex";

export default defineComponent({
    name: 'Home',
    components: {
        IonContent,
        IonPage,
        IonIcon
    },
    async ionViewWillEnter() {
        const currentUser = this.store.getters['auth/loggedInUser'];
        this.canCreateJourneySet = currentUser.permissions?.includes("CreateJourneySet");
        this.canCreateTemplate = currentUser.permissions?.includes("CreateJourneyTemplate");
        this.canSelectDisabled = currentUser.permissions?.includes("ManageAppInstances");
        this.canCreate = this.canCreateJourneySet || this.canCreateTemplate;
        await this.store.dispatch('app/setPageTitle', '');
        await execute( async () => {
            this.appInstances = await getCompleteAppInstances();
            const selectedApp = this.appInstances.find((instance: AppInstance) => instance.id === this.store.getters['app/selectedAppInstanceId']);
            this.preferredInstance = selectedApp?.name;
            this.isSuspended = selectedApp?.suspended ?? false;
            this.instanceSelectOptions = this.appInstances.map((instance: AppInstance) => {
                return {
                    value: instance.id,
                    text: instance.name + (instance.suspended ? ` - ${this.language.suspendedContact}` : ''),
                    isChecked: instance.id === this.store.getters['app/selectedAppInstanceId'],
                    disabled: instance.suspended
                } as SelectOption
            });
        }, this.language.errors.gettingAppInstances);
    },
    setup() {
        const store = useStore();
        const router = useRouter();
        const appInstances = ref([] as AppInstance[]);
        const preferredInstance = ref();
        const instanceSelectOptions = ref([] as SelectOption[]);
        const canCreateJourneySet = ref(false);
        const canCreateTemplate = ref(false);
        const canSelectDisabled = ref(false);
        const canCreate = ref(false);
        const language = computed(() => store.getters['app/language']);
        const isSuspended = ref(false);

        function goToNewJourneySet() {
            router.push({ name: 'journeySetInstructions' });
        }

        function goToNewTemplate() {
            router.push({ name: 'templateInstructions' });
        }

        function goToViewTemplates() {
            router.push({ name: 'templates' });
        }

        function goToViewJourneySets() {
            router.push({ name: 'journeySetGroups' });
        }

        async function selectPreference() {
            const partnersSelect = await openSelectModal(language.value.languages, 'radio', instanceSelectOptions.value,canSelectDisabled.value);
            partnersSelect.onWillDismiss().then(async ({ data }: any) => {
                preferredInstance.value = data.find((option: SelectOption) => option.isChecked)?.text;
                isSuspended.value = data.find((option: SelectOption) => option.isChecked)?.disabled;
                if(isSuspended.value) {
                  preferredInstance.value = preferredInstance.value.replace(` - ${language.value.suspendedContact}`,'');
                }
                
                const appInstanceId = data.find((option: SelectOption) => option.isChecked)?.value;
                await store.dispatch('app/setSelectedAppInstanceId', appInstanceId);

              instanceSelectOptions.value = appInstances.value.map((instance: AppInstance) => {
                return {
                  value: instance.id,
                  text: instance.name + (instance.suspended ? ` - ${language.value.suspendedContact}` : ''),
                  isChecked: instance.id === appInstanceId,
                  disabled: instance.suspended
                } as SelectOption
              });
            });          
        }
        
        return {
            goToNewJourneySet,
            goToNewTemplate,
            goToViewTemplates,
            goToViewJourneySets,
            preferredInstance,
            instanceSelectOptions,
            caretDownOutline,
            openSelectModal,
            appInstances,
            canCreateJourneySet,
            canSelectDisabled,
            canCreateTemplate,
            canCreate,
            isSuspended,
            selectPreference,
            language,
            store,
            notHome: computed(() => router.currentRoute.value.name !== 'home')
        }
    }
});
</script>

<style scoped>
* {
    text-align: center;
}

ion-select {
    max-width: 500px;
}

ion-select::part(icon){
    color: var(--color-step-1000);
    opacity: 1;
}

ion-select::part(icon) .select{
    left: 10px;
    top: 50%;
    margin-top: -2px;
    position: absolute;
    width: 0px;
    height: 0px;
    border-top: 10px solid;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    color: currentcolor;
    pointer-events: none;
}

.input-group .light-green{
    background-color: var(--color-primary-light);
}

.input-group p {
    color: var(--color-step-300);
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    width: 100%;
    margin-top: 0;
}

.custom-button {
    margin: 20px auto 0 auto;
    font-size: 24px;
}

.input-group button, .input-group ion-select {
    text-align: center;
}

.pattern-logo {
   font-size:165px;
   color:var(--ion-color-dark);

}
</style>